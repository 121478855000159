import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'

Vue.use(Vuex)

const apis = [
  {
    storeIn: 'trackList_en',
    path: 'https://xml.vintera.tv/widget_api/internettv.xml?format=json&lang=en',
    lang: 'en',
    nocache: true
  },
  {
    storeIn: 'trackList_ru',
    path: 'https://xml.vintera.tv/widget_api/internettv.xml?format=json&lang=ru',
    lang: 'ru',
    nocache: true
  },
  {
    storeIn: 'channelList_en',
    path: 'https://xml.vintera.tv/epg/epg_android.php?lang=en',
    lang: 'en'
  },
  {
    storeIn: 'channelList_ru',
    path: 'https://xml.vintera.tv/epg/epg_android.php?lang=ru',
    lang: 'ru'
  }
]

export default new Vuex.Store({
  state: {
    channelList_en: JSON.parse(localStorage.getItem('channelList_en')),
    channelList_ru: JSON.parse(localStorage.getItem('channelList_ru')),
    trackList_en: null,
    trackList_ru: null
  },

  mutations: {
    setState (state, payload) {
      state[payload.storeIn] = payload.data
    }
  },

  actions: {
    async updateContentData ({ state, commit }) {
      // adding 1 hour just to make sure everything is correct
      for (const api of apis) {
        if (
          !state[api.storeIn] ||
          moment()
            .isAfter(
              moment(state[api.storeIn].lastLocalUpdate)
                .endOf('day')
                .add(1, 'hours')
            )
        ) {
          await axios.get(api.path)
            .then(res => {
              const dataToSave = {
                ...res.data,
                lastLocalUpdate: moment().toISOString()
              }
              if (!api.nocache) {
                try {
                  localStorage.setItem(api.storeIn, JSON.stringify(dataToSave))
                } catch (e) {
                  console.error(e)
                }
              }
              commit('setState', {
                storeIn: api.storeIn,
                data: dataToSave
              })
            })
            .catch(e => {
              console.error(e)
            })
        }
      }
    }
  }
})
