function deviceName () {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows-phone'
  }

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  if ((/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad' ||
    (/mac os|Macintosh/i.test(userAgent) && navigator.maxTouchPoints > 0)) {
    return 'ios'
  }

  if (/LG Browser|LGE|NetCast/.test(userAgent)) {
    return 'lg-smart-tv'
  }

  return false
}

export default deviceName
