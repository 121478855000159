<template>
  <div class="common-page">
    <template v-if="pageName === 'index'">
      <TrackSlider
        v-for="(category, index) in categories"
        :key="category.id"
        :data="category"
        supplemented
        :show-ads="index === 1"
        @redirect="$emit('redirect', $event)"
      />
    </template>
    <template v-else-if="pageName === 'all'">
      <All
        v-if="channelList && trackList"
        :channel-list="channelList"
        :track-list="trackList"
        @redirect="$emit('redirect', $event)"
        :search-value="searchValue"
      />
    </template>
    <template v-else-if="pageName === 'channel'">
      <Channel v-if="channelList && trackList" :channel-list="channelList" :track-list="trackList" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

const parseDate = date => {
  if (date) {
    return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}T${date.slice(8, 10)}:${date.slice(10, 12)}:${date.slice(12, 14)}Z`
  }
}

export default {
  name: 'IndexPage',

  components: {
    TrackSlider: () => import('@/components/TrackSlider'),
    Channel: () => import('@/components/pages/Channel'),
    All: () => import('@/components/pages/All')
  },

  props: {
    pageName: {
      type: String,
      required: true
    },
    searchValue: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapState(['channelList_en', 'channelList_ru', 'trackList_en', 'trackList_ru']),

    data () {
      return {
        trackList_en: this.trackList_en,
        trackList_ru: this.trackList_ru,
        channelList_en: this.channelList_en,
        channelList_ru: this.channelList_ru
      }
    },

    channelList () {
      return this.data[`channelList_${this.$i18n.locale}`] || null
    },

    trackList () {
      return this.data[`trackList_${this.$i18n.locale}`] || null
    },

    tracks () {
      return this.trackList?.trackList?.track
        ?.map(t => {
          const now = moment()
          const channel = this.channelList?.channels
            ?.find(c => t.title === c.channel_name)

          const epg = channel ? this.channelList?.epg
            ?.find(e => e.channel_id === channel.channel_id &&
              now.isBetween(parseDate(e.time_from), parseDate(e.time_to))) : null

          return {
            ...t,
            epg
          }
        })
        .sort(e => e.track ? -1 : 1)
    },

    channels () {
      return this.channelList?.channels || null
    },

    categories () {
      return this.trackList ? Object.entries(this.trackList.categories).map(e => ({
        name: e[0],
        id: e[1],
        tracks: this.tracks?.filter(t => t.category === e[0])
      })).filter(c => c.tracks.length) : null
    }
  }
}
</script>
