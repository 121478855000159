<template>
  <div class="g-wrapper">
    <g-header
      :page-name="pageName"
      @redirect="onRedirect"
      :search-value="searchValue"
      @search="onSearch"
      :ios-link="iosLink"
      :android-link="androidLink"
      :lg-link="lgLink"
    />
    <router-view :page-name="pageName" @redirect="onRedirect" :search-value="searchValue" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import deviceName from '@/utils/deviceName'

export default {
  name: 'App',

  components: {
    GHeader: () => import('@/components/Header')
  },

  data () {
    return {
      searchValue: '',
      iosLink: 'https://apps.apple.com/ru/app/vintera-tv/id1570577786',
      androidLink: 'https://play.google.com/store/apps/details?id=tv.vintera.smarttv.v2&hl=ru',
      lgLink: 'https://ru.lgappstv.com/main/tvapp/detail?appId=176886&catCode1=003&moreYn=N&cateYn=Y&orderType=0&headerName=Развлекательные&appRankCode=1&sellrUsrNo=&curPage=2'
    }
  },

  async created () {
    if (this.pageName !== 'qr') {
      await this.updateContentData()
    } else {
      const device = deviceName()
      if (device === 'android') {
        this.goToLink(this.androidLink)
      } else {
        this.goToLink(this.iosLink)
      }
    }
  },

  computed: {
    pageName () {
      if (this.$route.query.all !== undefined) {
        return 'all'
      } else if (this.$route.query.channel !== undefined) {
        return 'channel'
      } else if (this.$route.query.qr !== undefined) {
        return 'qr'
      }
      return 'index'
    }
  },

  methods: {
    ...mapActions(['updateContentData']),

    onRedirect (route) {
      this.$router.push({ path: route.path, ...route.opts })
    },

    onSearch (v) {
      this.searchValue = v
    },

    goToLink (link) {
      window.location = link
    }
  }
}
</script>
