import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import { Swiper as SwiperClass, Navigation } from 'swiper/js/swiper.esm'
import LoadScript from 'vue-plugin-load-script'
import 'swiper/css/swiper.css'
import './assets/styles/app.scss'
import vClickOutside from 'v-click-outside'
import Ads from 'vue-google-adsense'

Vue.config.productionTip = false

SwiperClass.use([Navigation])
Vue.use(getAwesomeSwiper(SwiperClass))

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)
// Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-5025320822350726', isNewAdsCode: true })

Vue.use(LoadScript)
Vue.use(vClickOutside);

(async () => {
  await Vue.loadScript('//googleads.github.io/videojs-ima/node_modules/video.js/dist/video.min.js')
    .catch(e => {
      console.error(e)
    })
  await Vue.loadScript('//imasdk.googleapis.com/js/sdkloader/ima3.js')
    .catch(e => {
      console.error(e)
    })
  await Vue.loadScript('//googleads.github.io/videojs-ima/node_modules/videojs-contrib-ads/dist/videojs.ads.min.js')
    .catch(e => {
      console.error(e)
    })
  await Vue.loadScript('//googleads.github.io/videojs-ima/dist/videojs.ima.js')
    .catch(e => {
      console.error(e)
    })
  await Vue.loadScript('https://unpkg.com/@videojs/http-streaming@2.12.0/dist/videojs-http-streaming.min.js')
    .catch(e => {
      console.error(e)
    })
  await Vue.loadScript('https://yandex.ru/ads/system/context.js')
    .catch(e => {
      console.error(e)
    })

  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})()
